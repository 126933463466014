import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Classi / メドレー合同で AWS 勉強会を開催しました！",
  "date": "2018-12-13T06:02:49.000Z",
  "slug": "entry/2018/12/13/150249",
  "tags": ["medley"],
  "hero": "./2018_12_13.png",
  "heroAlt": "合同勉強会"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`みなさん、こんにちは。開発本部エンジニアの新居です。`}</p>
    <p>{`先日 12/7(金)、AWS の re:Invent の余韻冷めやらぬ中、メドレーと教育現場支援の ICT プラットフォームを展開している Classi さんとの合同で AWS 勉強会を開催しました。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="Real Tech Night #3 (2018/12/07 19:30〜)" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Frealtech-night.connpass.com%2Fevent%2F110083%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://realtech-night.connpass.com/event/110083/">realtech-night.connpass.com</a></cite>
    <p>{`サブタイトルに「AWS を最大限活用して教育/医療業界の課題をスマートに解決していく」とある通り、教育/医療業界ならではの課題を両社がどのように AWS を使って解決しているのかといったことを中心に、各社 3 名ずつ発表を行いました。`}</p>
    <p>{`当日は SNS 禁止で外部公開できないディープな話などもありましたが、この記事では簡単に当日の発表内容を紹介したいと思います。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181213/20181213125501.jpg",
        "alt": "f:id:medley_inc:20181213125501j:plain",
        "title": "f:id:medley_inc:20181213125501j:plain"
      }}></img><em parentName="p">{`場所はサポーターズさんのレンタルスペースをお借りしました`}</em></p>
    <h1>{`発表内容の紹介`}</h1>
    <h2>{`1. ガイドライン対応のための AWS セキュリティ系マネージドサービスの活用（メドレー 中畑）`}</h2>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181213/20181213125753.jpg",
        "alt": "f:id:medley_inc:20181213125753j:plain",
        "title": "f:id:medley_inc:20181213125753j:plain"
      }}></img><em parentName="p">{`メドレー 中畑の発表`}</em></p>
    <iframe id="talk_frame_481139" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/93d35b5d1fd34db993326e63ca593d65" width="710" height="399" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
    <p><a parentName="p" {...{
        "href": "https://speakerdeck.com/medley/real-tech-night-number-3"
      }}>{`https://speakerdeck.com/medley/real-tech-night-number-3`}</a></p>
    <p>{`一人目はメドレー中畑から、「CLINICS カルテ」と「3 省 3 ガイドライン」（`}<em parentName="p">{`開発当初は 3 省 4 ガイドラインでしたが、2018 年 7 月から 3 省 3 ガイドラインに変更されました。以下 3 省 3 ガイドライン`}</em>{`）、「CLINICS カルテで利用している AWS のセキュリティ系マネージドサービス」について発表しました。`}</p>
    <h3>{`発表内容の要約`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`3 省 3 ガイドラインとは「電子カルテをはじめ、電子化された医療情報をパブリッククラウドなどに外部保存する際に遵守する必要があるガイドライン」のことで、CLINICS カルテの開発を行う上でも遵守する必要がある`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`今回はそのガイドラインの中にある「アカウントごとの権限管理や各種操作のログ管理」を遵守するために AWS のサービスを使ってどのように対応しているかをメインで紹介`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`詳細は資料を見ていただくとして、主に利用しているサービスとして以下を紹介`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://aws.amazon.com/jp/cloudtrail/"
          }}>{`AWS CloudTrail`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://docs.aws.amazon.com/ja_jp/vpc/latest/userguide/flow-logs.html"
          }}>{`Flow logs`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://aws.amazon.com/jp/guardduty/"
          }}>{`Amazon GuardDuty`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://aws.amazon.com/jp/config/"
          }}>{`AWS Config`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://docs.aws.amazon.com/ja_jp/systems-manager/latest/userguide/systems-manager-paramstore.html"
          }}>{`Parameter Store`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://aws.amazon.com/jp/systems-manager/#Session_Manager"
          }}>{`Session Manager`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://aws.amazon.com/jp/blogs/news/new-amazon-cloudwatch-logs-insights-fast-interactive-log-analytics/"
          }}>{`CloudWatch Logs Insights`}</a></p>
      </li>
    </ul>
    <p>{`先日の re:Invent で発表された CloudWatch Logs Insights を早速導入していたりと、新しい技術に対して高いアンテナを持ち、日々改善を続けています。`}</p>
    <p>{`また、プロダクトの特性を踏まえて様々なマネージドサービスを活用することで、自前で仕組みを用意することなくセキュアにシステムを構築でき、プロダクト開発に集中できる環境作りを心掛けていることが伝わる発表でした。`}</p>
    <h2>{`2. システムを EC2 から Fargate へ安全にリプレイス（Classi 高田さん）`}</h2>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181213/20181213125515.jpg",
        "alt": "f:id:medley_inc:20181213125515j:plain",
        "title": "f:id:medley_inc:20181213125515j:plain"
      }}></img><em parentName="p">{`Classi 高田さんの発表`}</em></p>
    <iframe style={{
      "border": "1px solid #CCC",
      "borderWidth": "1px",
      "marginBottom": "5px",
      "maxWidth": "100%"
    }} src="https://www.slideshare.net/slideshow/embed_code/key/B2iXWrWaPeeKL7" width="427" height="356" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no" allowFullScreen=""></iframe>
https://www.slideshare.net/spinning13/real-tech-night-3-ec2fargate
    <p>{`二人目は Classi 高田さんで、現在 EC2 上で動いている既存システムを ECS の Fargate に安全に移行する方法についての発表でした。`}</p>
    <h3>{`発表内容の要約`}</h3>
    <ul>
      <li parentName="ul">{`ECS の起動タイプには「EC2」と「Fargate」の 2 種類があり、マイクロサービスアーキテクチャでアプリケーションの種類が多いケースなどでは EC2 インスタンスの管理が不要な Fargate が便利`}</li>
      <li parentName="ul">{`Fargate への移行の際は「既存の環境のシステムと相互に切り替えが可能なこと」と「切り替えが容易であること」などを考慮`}</li>
      <li parentName="ul">{`新環境と既存環境の振り分けには OpenResty を採用`}</li>
    </ul>
    <p>{`Fargate へのリプレイス時などには参考になる事例ではないでしょうか！`}</p>
    <h2>{`3. クライアント認証対応のための AWS 構成の変遷（メドレー 田中）`}</h2>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181213/20181213125521.jpg",
        "alt": "f:id:medley_inc:20181213125521j:plain",
        "title": "f:id:medley_inc:20181213125521j:plain"
      }}></img><em parentName="p">{`メドレー 田中の発表`}</em></p>
    <p>{`【発表資料は非公開】`}</p>
    <p>{`三人目はメドレー田中で、一人目の中畑に引き続き、3 省 3 ガイドラインへの対応について発表しました。`}</p>
    <p>{`3 省 3 ガイドラインの中でもシステム構成に特に影響が大きい内容として`}</p>
    <ul>
      <li parentName="ul">{`サービス提供に用いるシステム、アプリケーションを日本国内法の適用が及ぶ場所に設置すること`}</li>
      <li parentName="ul">{`クライアント証明書を利用した TLS クライアント認証を実施すること`}</li>
    </ul>
    <p>{`があり、これらを AWS を利用してどのように対応したかという内容でした。`}</p>
    <p>{`発表資料は非公開なので詳細には触れませんが、この辺の話については`}<a parentName="p" {...{
        "href": "/entry/2018/05/21/180652"
      }}>{`こちら`}</a>{`の記事にもありますので、興味がある方は御覧いただければと思います。`}</p>
    <h2>{`4. Classi での Amazon Elasticsearh Service の活用（Classi 中村さん）`}</h2>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181213/20181213125527.jpg",
        "alt": "f:id:medley_inc:20181213125527j:plain",
        "title": "f:id:medley_inc:20181213125527j:plain"
      }}></img><em parentName="p">{`Classi 中村さんの発表`}</em></p>
    <iframe id="talk_frame_481070" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/745e6cfc7dff484aa0d6b5f9527170aa" width="710" height="491" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
    <cite className="hatena-citation"></cite>
    <p><a parentName="p" {...{
        "href": "https://speakerdeck.com/nakaearth/classidefalseelasticsearchfalseli-yong-nituite"
      }}>{`https://speakerdeck.com/nakaearth/classidefalseelasticsearchfalseli-yong-nituite`}</a></p>
    <p>{`四人目は Classi 中村さんで、Classi で Elasticsearch をどのように利用しているかについての発表でした。`}</p>
    <h3>{`発表内容の要約`}</h3>
    <ul>
      <li parentName="ul">{`Classi の「コンテンツボックス」と「校内グループ」の機能で Amazon Elasticsearch Service を利用`}</li>
      <li parentName="ul">{`「コンテンツボックス」では、レスポンスが 10 秒以上掛かっていたものが 1~2 秒まで短縮できたが、Elasticsearch のバージョンアップに追随するためにどのように対応したかの紹介`}</li>
      <li parentName="ul">{`「校内グループ」では、当初学校毎に index を作成していたが、サービスの成長に伴い学校数が多くなり index の数は数千以上に。ここから安定稼働にためにどのような対応したかの紹介`}</li>
    </ul>
    <p>{`Elasticsearch は弊社でも利用しており、自分が担当している別のサービスでは 1 or 2 系から 5 系以上へのバージョンアップ（ちなみに 3 系 4 系はありません）も過去に経験しており、この辺の泥臭さは個人的には共感値が高かったです！`}</p>
    <h2>{`5. クラウドと院内システムをつなぐための AWS IoT の活用（メドレー 有馬）`}</h2>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181213/20181213125536.jpg",
        "alt": "f:id:medley_inc:20181213125536j:plain",
        "title": "f:id:medley_inc:20181213125536j:plain"
      }}></img><em parentName="p">{`メドレー 有馬の発表`}</em></p>
    <p>{`【発表資料は非公開】`}</p>
    <p>{`五人目はメドレー有馬で、クラウドと院内システムをつなぐための AWS IoT の活用について発表しました。`}</p>
    <p>{`CLINICS カルテを始めとした電子カルテでは、医療機関内の院内機器などの外部機器やサービスと連携する必要があります。`}</p>
    <p>{`こうした外部機器やサービスと、CLINICS カルテをどのように連携させていくのか？また可用性を担保しつつ、AWS をどう活用して実現していくのかという内容でした。`}</p>
    <p>{`こちらは SNS 禁止で外部公開できないディープな話でしたので、また別の機会でお話できるときがあればという感じですね。`}</p>
    <h2>{`6. ソシャゲ業界出身者が驚いた、学校教育支援システムの裏側（Classi 本間さん）`}</h2>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181213/20181213125547.jpg",
        "alt": "f:id:medley_inc:20181213125547j:plain",
        "title": "f:id:medley_inc:20181213125547j:plain"
      }}></img><em parentName="p">{`Classi 本間さんの発表`}</em></p>
    <iframe id="talk_frame_481386" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/dd7bda6207c54b8ea46d3b9c5f02e9b9" width="710" height="491" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
    <p><a parentName="p" {...{
        "href": "https://speakerdeck.com/ckreal/sosiyageye-jie-chu-shen-zhe-gajing-ita-xue-xiao-jiao-yu-zhi-yuan-sisutemufalseli-ce"
      }}>{`https://speakerdeck.com/ckreal/sosiyageye-jie-chu-shen-zhe-gajing-ita-xue-xiao-jiao-yu-zhi-yuan-sisutemufalseli-ce`}</a></p>
    <p>{`最後六人目は Classi 本間さん。Classi さんもメドレーも、前職でソーシャルゲーム業界だったメンバーが多数在籍しており、ソーシャルゲームの開発との違いなども絡めて Classi での AWS 活用事例の発表をいただきました。`}</p>
    <h3>{`発表内容の要約`}</h3>
    <ul>
      <li parentName="ul">{`教育業界特有のガイドラインについて`}</li>
      <li parentName="ul">{`本番環境への接続制限`}</li>
      <li parentName="ul">{`ECS 化と CloudWatch Logs の利用`}</li>
      <li parentName="ul">{`データベースの監査ログ取得`}</li>
      <li parentName="ul">{`CloudWatchLogs の費用感`}</li>
      <li parentName="ul">{`ManagementConsole 操作権限`}</li>
    </ul>
    <p>{`最後のまとめのところで`}</p>
    <ul>
      <li parentName="ul">{`学校向けのサービス提供は、セキュリティへの理解が重要`}</li>
      <li parentName="ul">{`セキュリティを保ちつつ、運用負荷を軽減する機能が AWS にはたくさんある`}</li>
      <li parentName="ul">{`とはいえ、もっともっと AWS を活用できる筈。なので…`}</li>
    </ul>
    <p>{`とありましたが、この辺は医療業界ともリンクする部分だよなあと改めて実感することができました。本番環境への接続制限など結構シビアにされているところも垣間見え、興味深いお話を聞くことができました。`}</p>
    <h1>{`懇親会`}</h1>
    <p>{`発表終了後は、お酒とフードを交えてざっくばらんに交流しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181213/20181213125552.jpg",
        "alt": "f:id:medley_inc:20181213125552j:plain",
        "title": "f:id:medley_inc:20181213125552j:plain"
      }}></img><em parentName="p">{`懇親会の様子`}</em></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181213/20181213125602.jpg",
        "alt": "f:id:medley_inc:20181213125602j:plain",
        "title": "f:id:medley_inc:20181213125602j:plain"
      }}></img><em parentName="p">{`本間さんの re:Invent 参加レポート`}</em></p>
    <p>{`本間さんからは re:Invent の参加レポートもあり、現地で体験した人の生のレポートが聞け、その興奮を少し感じることができたのは良かったです！`}</p>
    <iframe id="talk_frame_481440" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/d5928b5490434e499ed97fef30624bbb" width="710" height="491" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
    <cite className="hatena-citation"></cite>
    <p><a parentName="p" {...{
        "href": "https://speakerdeck.com/ckreal/re-inventnichu-can-jia-sitekitayo"
      }}>{`https://speakerdeck.com/ckreal/re-inventnichu-can-jia-sitekitayo`}</a></p>
    <h1>{`まとめ`}</h1>
    <p>{`教育業界と医療業界はもちろん別業界ではありますが、それぞれの業界で特有のセキュリティ要件があり、それらを AWS でどのように解決していくかなど、リンクする部分も多々あることを実感することができました。`}</p>
    <p>{`そして両社共、AWS に詳しいメンバーが多数在籍しており、AWS をフル活用して効率良く柔軟性を持たせたシステムを構築していることが垣間見えた会となりました。`}</p>
    <p>{`教育/医療業界というと Web 系のエンジニアにとってはまだまだ未知でよくわからないイメージなのかなあと思います。実際この業界でエンジニアとして働いていると、レガシーなシステムや業界特有の慣習がサービス開発の障壁となることもあります。`}</p>
    <p>{`しかしそういった障壁や課題を、新しい技術を適切に用いてうまく解決していくことがやりがいのひとつですし、そのような課題解決のひとつひとつがエンジニアとしての成長の糧にもなるのではないでしょうか。`}</p>
    <p>{`両社の歩みが教育業界と医療業界のそれぞれの発展になればとても素晴らしいことですし、その担い手となる仲間が一人でも多く増えることを切に願っています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181213/20181213125557.jpg",
        "alt": "f:id:medley_inc:20181213125557j:plain",
        "title": "f:id:medley_inc:20181213125557j:plain"
      }}></img></p>
    <p><em parentName="p">{`最後に両社のメンバーで記念撮影`}</em>{` `}</p>
    <p>{`「新しい医療体験を創造する」`}</p>
    <p>{`メドレーで働くクリエイターたちのストーリーを公開中。ぜひご覧ください。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="MEDLEY DESIGN&ENGINEERING" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      